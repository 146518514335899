import * as React from "react"
import PropTypes from "prop-types"
import "./css/infinite-loop.css"

const InfiniteLoop = ({ children, loop = 1 }) => {
  return (
    <div id="loop">
      <p className="loop-1">
        {Array.from(Array(loop), (e, i) => {
          return (
            <span key={i}>
              {children}
              {i + 1 < loop && <span className="dot" />}
            </span>
          )
        })}
      </p>
    </div>
  )
}

InfiniteLoop.propTypes = {
  children: PropTypes.node.isRequired,
  loop: PropTypes.number,
}

export default InfiniteLoop
