import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InfiniteLoop from "../components/infinite-loop"

const WhoIsKennethPage = () => {
  return (
    <Layout>
      <Seo title="Life-tuning | Who is Kenneth?" />
      <StaticImage
        style={{
          width: "100%",
          boxShadow: "0 15px 16px 2px rgba(0, 0, 0, 0.1)",
        }}
        src="../images/work-hard-life-tuning.jpeg"
        height={500}
        quality={100}
        objectFit={"cover"}
        objectPosition={"50% 100%"}
        formats={["auto", "webp", "avif"]}
        alt="dummy banner"
        placeholder="blurred"
      />
      <InfiniteLoop loop={8}>What is your why?</InfiniteLoop>
      <section className="section-last">
        <div className="text-wrapper ">
          <h2>Who is Kenneth?</h2>
          <StaticImage
            className="img-float"
            src="../images/kenneth.JPG"
            width={400}
            quality={100}
            formats={["auto", "webp", "avif"]}
            alt="Life-tuning | Kenneth from life-tuning"
            placeholder="blurred"
          />
          <p>
            I used to have the same life as many others: not great, not bad but
            simply “okay”. And let me tell you, there’s so much more to life
            than just “okay”. Here’s what happened to me when I decided enough
            was enough.
          </p>
          <p>
            I come from a broken family with an average mindset, living an
            average life, and like most people, I reproduced the same pattern.
          </p>
          <p>
            I was working a full time job that didn’t satisfy me, living in a
            failing relationship, dealing with financial issues and was having
            many other struggles we all tend to have. I was distracting myself
            from the day-to-day routine by working out, partying and traveling
            every once in a while.
          </p>
          <p>
            In short, I found myself just going through the motions, never
            really enjoying any of it. Yet, I kept on living like this for many
            years. After all, each time I looked around me it seemed to be the
            “normal” way to live
          </p>
          <p>Except this “normal” way of living led me to depression</p>
          <p>
            No matter how hard the sun was shining, everything looked gray. I
            felt alone, even when surrounded by people, and negativity and
            self-depreciation flooded my mind all day long. Since it’s automatic
            behavior for many people to avoid those who are going through hard
            times, I quickly noticed most of my friends and family were nowhere
            to be found.
          </p>
          <p>
            At some point, feeling abandoned made me realize that I had to stop
            hoping for others to help me change my life. I had to get out of the
            victim mentality and take full responsibility for my circumstances.
          </p>
          <p>
            I took a deep-dive into self development, I read as many books as I
            could, studied those I look up to, and followed multiple courses to
            understand the different aspects of life and how to make the most of
            every day. I started studying myself, my habits and thinking
            patterns in order to figure out where I went wrong and what I had to
            do in order to make a change for the better. Step by step things
            became clear, I implemented what I had learned and I actually
            started to feel better. I fell down many times and made mistakes but
            I always kept on learning and improving. After years of consistent
            effort, I was finally able to proudly say that I overcame my
            depression.
          </p>
          <p>
            My self development journey led me to develop a sincere and profound
            passion for life, as well as a powerful sense of empathy for others.
            I saw so many people around me just going through the motions, not
            realizing life has so much more to offer! I couldn’t help but wonder
            how impactful it would have been if I knew what I know now sooner. I
            decided to start sharing what I had learned in order to help others
            grow and reach their full potential as well.
          </p>
          <p>
            I realized that knowledge and experience alone wouldn’t be enough to
            make a difference, I had to learn how to teach it to others, so I
            took the plunge and got myself a coach. She taught me everything I
            needed to know about coaching and how to help others in the best way
            possible. That’s when things really started taking off, Life-tuning
            became a reality and after successfully coaching many clients of my
            own I can honestly say that I have found my “Why”. .
          </p>
          <p>
            If there’s one thing I’ve learned from my own life experience, as
            well as my clients’, it’s that we all have control over our own
            destiny. We all have the power to turn things around and to start
            living a full life. All it takes is self-awareness, determination
            and a willingness to work towards change.
          </p>
          <p>
            This whole process has been nothing but a blessing and I can’t wait
            for you to experience it too.
          </p>
          <p>
            So, I urge you to take charge of your life and become the person you
            want to be!
          </p>

          <p style={{ fontSize: "1.5rem", marginTop: "60px" }}>
            <span style={{ fontStyle: "italic", fontWeight: "bold" }}>
              “Your past does not equal your future.”
            </span>{" "}
            – Tony Robbins
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default WhoIsKennethPage
